import { action, makeObservable, observable, toJS } from 'mobx';
import shopify from 'shopify-buy';
import { useMemo } from 'react';
export const persistedStateId = 'CartCheckout';
import { getCookie, setCookie } from './cookie';
import { navigate } from 'gatsby';
import axios from 'axios'
import _ from 'lodash';

const decorate = {
  baseApiUrl: observable,
  shop: observable,
  showCartBolean: observable,
  shopifyCheckoutId: observable,
  hideCart: action,
  showCart: action,
  // addItem: action,
  addItem: action,
  loadingCart: observable,
  checkout: observable,
  isSubscription: observable,
  checkCartExistance: action,
  removeItem: action,
  resetCart: action,
  updateItem: action,
  setCheckout: action,
  setShopifyCheckoutId: action,
  checkoutUser: action,
  checkFreeGifts: action,
  // checkOffersToShow: action,
  confirmAndAddFreeGift: action,
  confirmParentForFreeGifts: action,
  findCustomAttributeByKey: action,
  addFreeGift: action,
  checkGiftVariantExistsInItems: action,
  getProductByHandle: action,
  Offers: observable,
  // offerProductsToShow: observable,
};

function createShopifyClient() {
  return shopify.buildClient({
    domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  });
}

class Store {
  baseApiUrl = process.env.GATSBY_API_URL || "http://localhost:1337"
  shop = process.env.GATSBY_SHOP_NAME ? `${process.env.GATSBY_SHOP_NAME}` : "prolonfast.myshopify.com"
  showCartBolean = false;
  checkout = { loaded: false };
  loadingCart = true;
  shopifyCheckoutId = null;
  isSubscription = null;
  RewardSection = {
    enabled: true,
    emptyMessage: "$25 Off Prolon + 30 Free Intermittent Fasting Bars",
    value: 100,
    inProgressMessage: `Congrats! 🎉 You have Received $25 Off Prolon + 30 Free Intermittent Fasting Bars!`,
    finalMessage: "Congrats! 🎉 You have Received $25 Off Prolon + 30 Free Intermittent Fasting Bars!",
    rewardType: "free_shipping",
    product_variant_id: "32612419338286",
    multipleReward: {
      enabled: true,
      product_id: "4634645430318",
      excludeCountProducts: ["32612419338286", "32593095098414", "32791770267694", "32791770431534", 32795610316846, 32795610251310, 32795610218542, 32795610153006,
        32817245585454, 32817246142510, 32817246601262, 32817246961710


      ],
      productvariantsByQty: {
        4634645430318: 3,
        32593125769262: 3,
        32791770267694: 0,
        32791770431534: 0
      }
    },
    condtionalBy: [
      {
        conditiontype: "amount",
        type: "specific_collection",
        value: "free-fastbar-gift"
      },
      {
        conditiontype: "count",
        type: "specific_collection",
        value: "free-fastbar-gift",
        minCount: 1
      },
      {
        conditiontype: "exclude",
        type: "specific_variants",
        value: ["32791770267694", "32791770431534", 32795610316846, 32795610251310, 32795610218542, 32795610153006,
          32817245585454, 32817246142510, 32817246601262, 32817246961710

        ],

      }

    ]
  };

  client = createShopifyClient();
  constructor() {
    makeObservable(this, decorate);

  }
  setShopifyCheckoutId(value) {
    if (value && value != '') {
      this.setLocal(persistedStateId, value);
      this.shopifyCheckoutId = value;
    }
  }
  hideCart() {
    this.showCartBolean = false;
  }
  setLocal(key, value) {

    if (typeof window != undefined && window) {
      setCookie(key, value);
    }

  }
  getLocal(key) {
    if (typeof window != undefined && window) {
      return getCookie(key);
    }
  }
  showCart() {
    this.showCartBolean = true;
    this.checkCartExistance();
  }
  setCheckout(tempCheckout) {
    const { lineItems = [], subtotalPrice = 0, webUrl = '' } = tempCheckout;
    this.checkout = { lineItems, subtotalPrice, webUrl, loaded: true };

    // this.checkFreeGifts(lineItems)
    // this.checkOffersToShow(lineItems)
    // this.confirmDiscoutCodeRemoval()
    this.loadingCart = false;
  }
  // addItem = async ({ variantId, quantity }) => {
  //   console.log('variantId :>> ', variantId);

  //   const temporalCheckout = await this.client.checkout.addLineItems(
  //     this.shopifyCheckoutId,
  //     [{ variantId, quantity }]
  //   );

  //   this.setCheckout(temporalCheckout);
  // }
  addItem = async ({ variantId, quantity, customAttributes }) => {

    let lineItem = { variantId, quantity }
    // TODO: Remove these hardcode values ALI RAZA
    let attribute1 = this.findCustomAttributeByKey('_free_with', { variantId, quantity, customAttributes })
    let attribute2 = this.findCustomAttributeByKey('_from_offer', { variantId, quantity, customAttributes })
    if (!attribute1 && !attribute2) {
      customAttributes.push(
        {
          key: "_offer",
          value: "offer_1"
        },
        {
          key: "_free_gift",
          value: "free-gift-fast-bar-variety"
        }
      )
    }
    if (customAttributes.length > 0) {
      lineItem.customAttributes = customAttributes
    }
    const temporalCheckout = await this.client.checkout.addLineItems(
      this.shopifyCheckoutId,
      [lineItem]
    );
    this.setCheckout(temporalCheckout);
    this.checkFreeGifts(temporalCheckout.lineItems)
    // this.checkOffersToShow(lineItems)
    // this.confirmDiscoutCodeRemoval()
  }
  confirmDiscoutCodeRemoval = async () => {
    if (this.checkout.lineItems && this.checkout.lineItems.length == 0) {
      console.log('Removing Discount...');
      this.client.checkout.removeDiscount(this.shopifyCheckoutId).then(checkout => {
        // Do something with the updated checkout
        this.setCheckout(checkout)
      });
    }
  }
  async checkFreeGifts(items) {
    await this.confirmAndAddFreeGift(items)
    await this.confirmParentForFreeGifts(items)
  }
  async confirmParentForFreeGifts(items) {
    try {
      for (let item of items) {
        let attribute = this.findCustomAttributeByKey('_free_with', item)
        if (!attribute) continue
        await this.confirmGiftParent(attribute.value, item.id)
      }
    } catch (error) {
      console.log('confirmParentForFreeGifts error: ', error);
    } finally {
    }
  }
  findCustomAttributeByKey(key, item) {
    if (!item.customAttributes || item.customAttributes.length == 0) return null
    for (let attr of item.customAttributes) {
      if (attr.key == key) return attr
    }
    return null
  }
  async confirmAndAddFreeGift(items) {
    try {
      for (let item of items) {
        let attribute = this.findCustomAttributeByKey('_free_gift', item)
        if (!attribute) continue
        await this.addFreeGift(attribute.value, item)
      }
    } catch (error) {
      console.log('confirmAndAddFreeGift error: ', error);
    } finally {
    }
  }
  async confirmGiftParent(parentId, childId) {
    let parentExists = false
    for (let rec of this.checkout.lineItems) {
      if (rec.variant.id == parentId) {
        parentExists = true
        break
      }
    }
    // console.log('parentExists: ', parentExists);
    if (!parentExists) {
      await this.removeItem(childId)
    }
  }
  async addFreeGift(handle, parentItem) {
    try {
      // console.log('handle: ', handle);
      const giftProduct = await this.client.product.fetchByHandle(handle)
      let giftVariantId = giftProduct.variants[0].id
      let alreadyAdded = await this.checkGiftVariantExistsInItems(giftVariantId, this.checkout.lineItems)
      if (alreadyAdded) return
      await this.addItem({
        variantId: giftVariantId,
        quantity: 1,
        customAttributes: [
          {
            key: "_free_with",
            value: parentItem.variant.id
          }
        ]
      })
    } catch (error) {
      console.log('confirmAndCreateFreeGift error: ', error);
    } finally {
    }
  }
  async checkGiftVariantExistsInItems(variantId, items) {
    for (let item of items) {
      if (item.variant.id == variantId) {
        // await this.updateItem({
        //   id: item.id, quantity: 1
        // })
        return item;
      }
    }
    return false
  }
  async createNewCheckout() {
    const checkout = await this.client.checkout.create();
    this.setShopifyCheckoutId(checkout.id);
    return checkout;
  }
  async removeItem(variantId) {
    const temporalCheckout = await this.client.checkout.removeLineItems(
      this.shopifyCheckoutId,
      [variantId]
    );
    this.setCheckout(temporalCheckout);
    this.checkFreeGifts(temporalCheckout.lineItems)
  }

  resetCart() {
    this.setShopifyCheckoutId('');
    this.checkout = { loaded: false };
  }

  async updateItem({ id, quantity }) {
    return new Promise(async resolve => {

      const temporalCheckout = await this.client.checkout.updateLineItems(
        this.shopifyCheckoutId,
        [
          {
            id,
            quantity,
          },
        ]
      );

      this.setCheckout(temporalCheckout);

      resolve('ok');
    })

  }
  async updateItemByVariantId({ variantId, quantity }) {
    return new Promise(async resolve => {

      const temporalCheckout = await this.client.checkout.updateLineItems(
        this.shopifyCheckoutId,
        [
          {
            variantId,
            quantity,
          },
        ]
      );

      this.setCheckout(temporalCheckout);
      resolve('ok');
    })

  }

  async checkCartExistance() {
    let temporalCheckout = null;
    let shopifyCheckoutId = null;
    try {
      if (!this.client || !this.client.checkout) return;

      shopifyCheckoutId = this.getLocal(persistedStateId);
      this.shopifyCheckoutId = shopifyCheckoutId;

      if (shopifyCheckoutId && shopifyCheckoutId == '') {
        temporalCheckout = await this.createNewCheckout();
      } else {
        console.log(shopifyCheckoutId);
        temporalCheckout = await this.client.checkout.fetch(shopifyCheckoutId);
        if (temporalCheckout === null) {
          temporalCheckout = await this.createNewCheckout();
        }
      }
    }
    catch (ex) {
      console.log(ex);
      temporalCheckout = await this.createNewCheckout();


    }
    this.setCheckout(temporalCheckout);

  }
  async checkoutUser(checkoutBtn = null, discount_code = null) {

    console.log('checkout?.webUrl: ', this.checkout?.webUrl);
    if (
      !this.checkout?.lineItems ||
      this.checkout?.lineItems.length == 0 ||
      !this.shopifyCheckoutId ||
      this.shopifyCheckoutId == ''
    ) {
      console.log('No Items');
      alert(`Your cart is empty..`);
      return
    }
    if (checkoutBtn)
      checkoutBtn.current.className = checkoutBtn.current.className + " btn--loading";

    const { lineItems } = this.checkout;
    let isSubscription = false
    for (let item of lineItems) {
      if (!item.customAttributes || item.customAttributes.length == 0) {
        continue;
      }
      for (let attr of item.customAttributes) {
        if (attr.key == 'subscription' && attr.value == 'true') {
          isSubscription = true
          break
        }
      }
      if (isSubscription) {
        break;
      }
    }

    console.log('isSubscription: ', isSubscription);
    if (!isSubscription) {
      navigate(this.checkout?.webUrl)
    } else {
      try {
        let data = {
          lineItems: this.checkout?.lineItems,
          discount_code
        }
        const checkoutResponse = await this.createRechargePaymentCheckout(data)

        if (checkoutResponse.status == 'ok') {
          navigate(checkoutResponse.url)
        }
        else {
          if (checkoutBtn)
            checkoutBtn.current.className = checkoutBtn.current.className.replace('btn--loading', '');

          alert(checkoutResponse.error);
        }


      }
      catch (ex) {
        checkoutBtn.current.className = checkoutBtn.current.className.replace('btn--loading', '');

        console.log(ex);
      }
    }
  }
  async createRechargePaymentCheckout(lineItems) {


    // console.log('lineItems: ', lineItems);
    try {
      const { data } = await this.sendRequest(`/stores/create-recharge-checkout`, lineItems, 'POST', this.shop)
      // console.log('data: ', data);
      return data
    } catch (error) {
      console.log('error: ', error);
    }

  }
  sendGetRequest = async (url, params = {}, shop = null) => {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    if (shop) {
      url += `?shop=${shop}`
    }
    return axios({
      method: 'GET',
      headers,
      url: this.baseApiUrl + url,
      params
    });
  }
  sendRequest = async (url, data, method = 'POST', shop = null) => {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    if (shop) {
      url += `?shop=${shop}`
    }
    return await axios({
      method: method,
      headers,
      url: this.baseApiUrl + url,
      data: data
    });
  }

  Offers = [
    {
      key: 'offer_1',
      product: 'buy-prolon-lpw'
    }
  ]
  // offerProductsToShow = []
  // checkOffersToShow = async (items) => {
  //   try {
  //     for (let item of items) {
  //       // let attribute = this.findCustomAttributeByKey('offer', item)
  //       // if (!attribute) continue
  //       // let offer = this.getOfferDetails(attribute.value)
  //       // if (!offer) continue
  //       // let offerProduct = await this.getProductByHandle(offer.product)
  //       // console.log('offerProduct: ', offerProduct);
  //       // this.offerProductsToShow.push(offerProduct)
  //     }
  //   } catch (error) {
  //     console.log('checkOffersToShow error: ', error);
  //   } finally {
  //   }
  // }
  // getOfferDetails = (offer) => {
  //   let rec = this.Offers.find(o => o.key == offer)
  //   return rec
  // }
  getProductByHandle = async (handle) => {
    try {
      const product = await this.client.product.fetchByHandle(handle)
      return product
    } catch (error) {
      console.log('getProductByHandle error: ', error);
      return null
    }
  }

  getDataFromVarient = ({ variants, option1, option2, free_gift = false, quantity = 1, subscription, attributes }) => {
    // console.log('propsss', props)
    // return
    let variant = _.find(variants, (e) => {
      return e.option1 == option1 && e.option2 == option2;
    });

    let variantId = variant.shopifyId;

    let data = { variantId, quantity: quantity, customAttributes: [] };
    if (subscription) {
      let attr = [
        {
          key: 'subscription',
          value: 'true',
        },
        {
          key: 'order_interval_unit',
          value: 'month',
        },
        {
          key: 'order_interval_frequency',
          value: '1',
        },
        {
          key: 'charge_interval_frequency',
          value: '1',
        },
      ];
      data.customAttributes = attr;
    }
    if (attributes) {
      data.customAttributes = attributes;
    }

    if (free_gift && free_gift != '') {
      data.customAttributes.push({
        key: 'free_gift',
        value: free_gift,
      });
    }
    return data
  }
}
const store = new Store();
export default store;
